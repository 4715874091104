import React from "react"
import { Link } from "gatsby"
import { useInView } from "react-intersection-observer"

import Layout from "../../components/layout"
import Header from "../../components/header"
import Projects from "../../components/projects"


const Suenos = () => {
  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 0,
  })

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" })
  }

  return (
    <Layout>
      <Header />
      <div className="project">
        <div className="container-fluid subheader">
          <div className="row">
            <div className="col-lg-4">
              <h2>03. Sueños: Nike X Mexico National Team</h2>
            </div>
            <div className="offset-lg-1 col-lg-3">
              <h2>2020</h2>
            </div>
            <div className="col-lg-4">
              <h2>Commercial</h2>
            </div>
          </div>
        </div>
        <div className="container-fluid p-0">
          <div className="row no-gutters">
            <div className="col-12 hero hero-sueno">
              <div className="hero-video-container">
                <video
                  className="hero-video"
                  autoPlay
                  loop
                  controls
                  playsInline
                >
                  <source src="https://res.cloudinary.com/rwh/video/upload/v1607105681/rgrq/static/sue%C3%B1os/rgrq_nike_video_mexico_ts9c0c.mp4" type="video/mp4" />
                </video>
              </div>
              <div className={`hero-footer ${inView}`}>
                <div className="row justify-content-between align-items-end">
                  <div className="col-lg-5 d-none d-md-block">
                    <h2>
                      <Link to="/projects/fifa-museum">Previous Project(02) ↖</Link>
                    </h2>
                    <h2>
                      <Link to="/projects/artcenter-library">Next Project(04) ↗</Link>
                    </h2>
                  </div>
                  <div className="col-lg-3 d-none d-md-block">
                    <h2>
                      <Link to="/">rg-rq.com</Link>
                    </h2>
                  </div>
                  <div className="offset-md-2 col-md-2">
                    <button onClick={scrollTop}>
                      <h2>
                        Back up
                        {inView ? (
                          <img
                            className="img-fluid"
                            src="/Up.svg"
                            alt="cheveron icon"
                          />
                        ) : (
                          <img
                            className="img-fluid"
                            src="/Up-white.svg"
                            alt="cheveron icon"
                          />
                        )}
                      </h2>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div ref={ref}></div>
      <Projects />
    </Layout>
  )
}
export default Suenos
